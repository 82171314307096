const translations = {
  // routes
  "/skapa-din-saga": {
    sv: "/skapa-din-saga",
    pl: "/stwórz-swoją-bajkę",
    en: "/create-your-fairy-tale",
  },
  "/köp-sagomynt": {
    sv: "/köp-sagomynt",
    pl: "/kup-monety-bajkowe",
    en: "/buy-fairy-tale-coins",
  },
  "/bibliotek": {
    sv: "/bibliotek",
    pl: "/biblioteka",
    en: "/library",
  },
  "/författare": {
    sv: "/författare",
    pl: "/autorzy",
    en: "/authors",
  },
  "/skapa-konto": {
    sv: "/skapa-konto",
    pl: "/stwórz-konto",
    en: "/create-account",
  },
  "/logga-in": {
    sv: "/logga-in",
    pl: "/zaloguj-się",
    en: "/log-in",
  },
  "/glömt-lösenord": {
    sv: "/glömt-lösenord",
    pl: "/zapomniałeś-hasła",
    en: "/forgotten-password",
  },
  "/profil": {
    sv: "/profil",
    pl: "/profil",
    en: "/profile",
  },
  "/saga": {
    sv: "/saga",
    pl: "/bajka",
    en: "/fairy-tale",
  },
  "/pub-sagor": {
    sv: "/pub-sagor",
    pl: "/publikowane-bajki",
    en: "/published-fairy-tales",
  },
  "/delad": {
    sv: "/delad",
    pl: "/udostępnione",
    en: "/shared",
  },
  "/mina-sagor": {
    sv: "/mina-sagor",
    pl: "/moje-bajki",
    en: "/my-fairy-tales",
  },
  "/din-saga-skapas": {
    sv: "/din-saga-skapas",
    pl: "/tworzenie-twojej-bajki",
    en: "/your-fairy-tale-is-being-created",
  },
  "/beställ-bok": {
    sv: "/beställ-bok",
    pl: "/zamów-książkę",
    en: "/order-book",
  },

  //Header
  "Sagor - ": {
    sv: "Sagor - ",
    pl: "Bajki - ",
    en: "Fairy tales - ",
  },
  "Skapa saga": {
    sv: "Skapa saga",
    pl: "Stwórz bajkę",
    en: "Create fairy tale",
  },
  "Köp sagomynt": {
    sv: "Köp sagomynt",
    pl: "Kup monety bajkowe",
    en: "Buy fairy tale coins",
  },
  Sagor: {
    sv: "Sagor",
    pl: "Bajki",
    en: "Fairy tales",
  },
  Författare: { sv: "Författare", pl: "Autorski", en: "Authors" },
  "Skapa konto": {
    sv: "Skapa konto",
    pl: "Stwórz konto",
    en: "Create account",
  },

  "Logga in": {
    sv: "Logga in",
    pl: "Zaloguj się",
    en: "Log in",
  },
  "Glömt lösenord": {
    sv: "Glömt lösenord",
    pl: "Zapomniałeś hasła",
    en: "Forgotten password",
  },

  "Logga ut": {
    sv: "Logga ut",
    pl: "Wyloguj się",
    en: "Log out",
  },
  Profil: {
    sv: "Profil",
    pl: "Profil",
    en: "Profile",
  },
  "Sagor - mina sagor": {
    sv: "Sagor - mina sagor",
    pl: "Bajki - moje bajki",
    en: "Fairy tales - my fairy tales",
  },
  "Sagor - Din saga skapas": {
    sv: "Sagor - Din saga skapas",
    pl: "Bajki - Tworzenie twojej bajki",
    en: "Fairy tales - Your fairy tale is being created",
  },

  // Notifications
  "Eftersom det är första gången du loggar in idag har vi fyllt på ditt konto med 10 sagomynt!":
    {
      sv: "Eftersom det är första gången du loggar in idag har vi fyllt på ditt konto med 10 sagomynt!",
      pl: "Ponieważ jest to pierwszy raz, gdy logujesz się dzisiaj, doładowaliśmy twoje konto o 10 monet bajkowych!",
      en: "Since this is the first time you log in today, we have topped up your account with 10 fairy tale coins!",
    },

  // LibraryView
  "Mina sagor": { sv: "Mina sagor", pl: "Moje bajki", en: "My fairy tales" },
  "Våra sagor": { sv: "Våra sagor", pl: "Nasze bajki", en: "Our fairy tales" },
  "Sagor - Läs våra sagor": {
    sv: "Sagor - Läs våra sagor",
    pl: "Bajki - Czytaj nasze bajki",
    en: "Fairy tales - Read our fairy tales",
  },

  // LoginView
  "Sagostund - Logga in": {
    sv: "Sagostund - Logga in",
    pl: "Bajki dla dzieci - Zaloguj się",
    en: "Fairy tales for children - Log in",
  },
  "Just nu får nya användare 100 gratis sagomynt!": {
    sv: "Just nu får nya användare 100 gratis sagomynt!",
    pl: "Obecnie nowi użytkownicy otrzymują 100 darmowych monet bajkowych!",
    en: "Right now, new users get 100 free fairy tale coins!",
  },
  Lösenord: {
    sv: "Lösenord",
    pl: "Hasło",
    en: "Password",
  },
  "Felaktiga inloggningsuppgifter": {
    sv: "Felaktiga inloggningsuppgifter",
    pl: "Nieprawidłowe dane logowania",
    en: "Incorrect login details",
  },
  "Logga in med Google": {
    sv: "Logga in med Google",
    pl: "Zaloguj się przez Google",
    en: "Log in with Google",
  },

  //BookOrderView
  "Denna sidan är inte riktigt klar än!": {
    sv: "Denna sidan är inte riktigt klar än!",
    pl: "Ta strona nie jest jeszcze gotowa!",
    en: "This page is not quite ready yet!",
  },

  "Hej! Vi jobbar hårt med att göra så att man kan beställa en fysisk kopia av sin saga. Tyvärr är vi inte klara riktigt ännu men håll utkik på vår instagram sagostund_nu för att få reda på när det är färdigt!":
    {
      sv: "Hej! Vi jobbar hårt med att göra så att man kan beställa en fysisk kopia av sin saga. Tyvärr är vi inte klara riktigt ännu men håll utkik på vår instagram @sagostund_nu för att få reda på när det är färdigt!",
      pl: "Cześć! Ciężko pracujemy, aby umożliwić zamawianie fizycznej kopii swojej bajki. Niestety, nie jesteśmy jeszcze gotowi, ale sprawdź nasz instagram @sagostund_nu, aby dowiedzieć się, kiedy to się stanie!",
      en: "Hi! We are working hard to make it possible to order a physical copy of your fairy tale. Unfortunately, we are not quite ready yet, but check out our instagram @sagostund_nu to find out when it is ready!",
    },

  "Gå till mina sagor": {
    sv: "Gå till mina sagor",
    pl: "Przejdź do moich bajek",
    en: "Go to my fairy tales",
  },

  // CreateAccountView
  "Repetera lösenord": {
    sv: "Repetera lösenord",
    pl: "Powtórz hasło",
    en: "Repeat password",
  },
  "Något gick fel, kontrollera dina uppgifter och försök igen": {
    sv: "Något gick fel, kontrollera dina uppgifter och försök igen",
    pl: "Coś poszło nie tak, sprawdź swoje dane i spróbuj ponownie",
    en: "Something went wrong, check your details and try again",
  },
  "Fortsätt med Google": {
    sv: "Fortsätt med Google",
    pl: "Kontynuuj z Google",
    en: "Continue with Google",
  },

  "Tillbaka till Logga In": {
    sv: "Tillbaka till Logga In",
    pl: "Wróć do Logowania",
    en: "Back to Log In",
  },
  "Sagostund - Skapa konto": {
    sv: "Sagostund - Skapa konto",
    pl: "Bajki dla dzieci - Stwórz konto",
    en: "Fairy tales for children - Create account",
  },
  "Lösenorden matchar inte": {
    sv: "Lösenorden matchar inte",
    pl: "Hasła nie pasują do siebie",
    en: "Passwords do not match",
  },

  // BookOrderView
  "Sagostund - Beställ bok": {
    sv: "Sagostund - Beställ bok",
    pl: "Bajki dla dzieci - Zamów książkę",
    en: "Fairy tales for children - Order book",
  },
  // ProfileView

  "E-post": {
    sv: "E-post",
    pl: "E-mail",
    en: "E-mail",
  },

  "Ändra ditt lösenord": {
    sv: "Ändra ditt lösenord",
    pl: "Zmień swoje hasło",
    en: "Change your password",
  },
  "Nuvarande lösenord": {
    sv: "Nuvarande lösenord",
    pl: "Aktualne hasło",
    en: "Current password",
  },
  "Nytt lösenord": {
    sv: "Nytt lösenord",
    pl: "Nowe hasło",
    en: "New password",
  },
  "Bekräfta nytt lösenord": {
    sv: "Bekräfta nytt lösenord",
    pl: "Potwierdź nowe hasło",
    en: "Confirm new password",
  },
  "Uppdatera lösenord": {
    sv: "Uppdatera lösenord",
    pl: "Zaktualizuj hasło",
    en: "Update password",
  },
  "Radera konto": {
    sv: "Radera konto",
    pl: "Usuń konto",
    en: "Delete account",
  },
  "Välj bilden passar bäst som din profilbild!": {
    sv: "Välj bilden passar bäst som din profilbild!",
    pl: "Wybierz obraz, który najlepiej pasuje jako twój obraz profilowy!",
    en: "Choose the image that best suits as your profile picture!",
  },
  "Skapar din nya profilbild": {
    sv: "Skapar din nya profilbild",
    pl: "Tworzenie twojego nowego obrazu profilowego",
    en: "Creating your new profile picture",
  },
  "Sagor - Profil": {
    sv: "Sagor - Profil",
    pl: "Bajki - Profil",
    en: "Fairy tales - Profile",
  },

  // FörfattareView
  "Läs mer på wikipedia": {
    sv: "Läs mer på wikipedia",
    pl: "Czytaj więcej na wikipedia",
    en: "Read more on wikipedia",
  },
  "Sagor för barn - Författare": {
    sv: "Sagor för barn - Författare",
    pl: "Bajki dla dzieci - Autorzy",
    en: "Fairy tales for children - Authors",
  },
  "Fyll på med sagomynt för ditt skapande!": {
    sv: "Fyll på med sagomynt för ditt skapande!",
    pl: "Uzupełnij swoje monety bajkowe dla twojego twórczości!",
    en: "Top up your fairy tale coins for your creativity!",
  },
  sagomynt: { sv: "sagomynt", pl: "monety bajkowe", en: "fairy tale coins" },
  Kostar: { sv: "Kostar", pl: "Kosztuje", en: "Costs" },
  "Räcker till": { sv: "Räcker till", pl: "Wystarcza na", en: "Enough for" },
  "Köp nu": { sv: "Köp nu", pl: "Kup teraz", en: "Buy now" },
  kr: { sv: "kr ", pl: "zł", en: "$" },
  saga: { sv: "saga", pl: "bajka", en: "fairy tale" },
  sagor: { sv: "sagor", pl: "bajki", en: "fairy tales" },
  "kr/saga:": { sv: "kr/saga:", pl: "zł/bajka:", en: "$/fairy tale:" },
  Hjälp: { sv: "Hjälp", pl: "Pomoc", en: "Help" },
  "Skapa din saga": {
    sv: "Skapa din saga",
    pl: "Stwórz swoją bajkę",
    en: "Create your fairy tale",
  },
  "Vad ska huvudkaraktären heta?": {
    sv: "Vad ska huvudkaraktären heta?",
    pl: "Jak powinna nazywać się główna postać?",
    en: "What should the main character be called?",
  },
  "Beskriv huvudkaraktären": {
    sv: "Beskriv huvudkaraktären",
    pl: "Opisz główną postać",
    en: "Describe the main character",
  },
  "Vad ska sagan handla om?": {
    sv: "Vad ska sagan handla om?",
    pl: "O czym powinna być bajka?",
    en: "What should the fairy tale be about?",
  },
  "Hur ska sagan vara skriven?": {
    sv: "Hur ska sagan vara skriven?",
    pl: "Jak powinna być napisana bajka?",
    en: "How should the fairy tale be written?",
  },
  "Vilken stil ska bilderna ha?": {
    sv: "Vilken stil ska bilderna ha?",
    pl: "Jaki styl powinny mieć obrazki?",
    en: "What style should the pictures have?",
  },
  "Skapa!": { sv: "Skapa!", pl: "Stwórz!", en: "Create!" },
  "Skapar din saga!": {
    sv: "Skapar din saga!",
    pl: "Tworzenie twojej bajki!",
    en: "Creating your fairy tale!",
  },
  "Du måste fylla på ditt konto med sagomynt för att skapa en ny saga!": {
    sv: "Du måste fylla på ditt konto med sagomynt för att skapa en ny saga!",
    pl: "Musisz doładować swoje konto monetami bajkowymi, aby stworzyć nową bajkę!",
    en: "You must top up your account with fairy tale coins to create a new fairy tale!",
  },
  "Huvudkaraktären heter:": {
    sv: "Huvudkaraktären heter:",
    pl: "Główna postać nazywa się:",
    en: "The main character is called:",
  },
  "Här är en beskrivning av": {
    sv: "Här är en beskrivning av",
    pl: "Oto opis",
    en: "Here is a description of",
  },
  "Sagan handlar om:": {
    sv: "Sagan handlar om:",
    pl: "Bajka opowiada o:",
    en: "The fairy tale is about:",
  },
  "Sagan ska vara skriven på följande sätt:": {
    sv: "Sagan ska vara skriven på följande sätt:",
    pl: "Bajka powinna być napisana w następujący sposób:",
    en: "The fairy tale should be written as follows:",
  },
  "Sagor för barn - Skapa": {
    sv: "Sagor för barn - Skapa",
    pl: "Bajki dla dzieci - Stwórz",
    en: "Fairy tales for children - Create",
  },
  " Skriv en saga!": {
    sv: " Skriv en saga!",
    pl: " Napisz bajkę!",
    en: " Write a fairy tale!",
  },
  "Fyll på!": { sv: "Fyll på!", pl: "Uzupełnij!", en: "Top up!" },
  Rolig: { sv: "Rolig", pl: "Zabawny", en: "Funny" },
  Spännande: { sv: "Spännande", pl: "Ekscytujący", en: "Exciting" },
  Tramsig: { sv: "Tramsig", pl: "Głupi", en: "Silly" },
  Läskig: { sv: "Läskig", pl: "Straszny", en: "Scary" },
  Lärdomsaktig: { sv: "Lärdomsaktig", pl: "Pouczający", en: "Educational" },
  Kaotisk: { sv: "Kaotisk", pl: "Chaotyczny", en: "Chaotic" },
  "Sagor för barn": {
    sv: "Sagor för barn",
    pl: "Bajki dla dzieci",
    en: "Fairy tales for children",
  },
  "Just nu får nya användare 100 gratis sagomynt att skapa sagor med!": {
    sv: "Just nu får nya användare 100 gratis sagomynt att skapa sagor med!",
    pl: "Obecnie nowi użytkownicy otrzymują 100 darmowych monet bajkowych do tworzenia bajek!",
    en: "Right now, new users get 100 free fairy tale coins to create fairy tales with!",
  },
  "Skapa egna sagor för barn med fantastiska bilder och ljud med vårt roliga verktyg. Testa att skapa en saga helt gratis!":
    {
      sv: "Skapa egna sagor för barn med fantastiska bilder och ljud med vårt roliga verktyg. Testa att skapa en saga helt gratis!",
      pl: "Stwórz własne bajki dla dzieci z fantastycznymi obrazkami i dźwiękami za pomocą naszego wspaniałego narzędzia. Wypróbuj tworzenie bajki za darmo!",
      en: "Create your own fairy tales for children with amazing pictures and sounds with our fun tool. Try creating a fairy tale for free!",
    },
  "Utforska och skapa sagor för barn med vår plattform. Hos oss kan du skapa nya egna sagor och läsa unika sagor för att inspirera ditt barns fantasi.":
    {
      sv: "Utforska och skapa sagor för barn med vår plattform. Hos oss kan du skapa nya egna sagor och läsa unika sagor för att inspirera ditt barns fantasi.",
      pl: "Odkrywaj i twórz bajki dla dzieci z naszą platformą. U nas możesz tworzyć nowe bajki i czytać unikalne bajki, aby inspirować wyobraźnię swojego dziecka.",
      en: "Explore and create fairy tales for children with our platform. With us you can create new fairy tales and read unique fairy tales to inspire your child's imagination.",
    },
  "Sagostund - Skapa sagor för barn!": {
    sv: "Sagostund - Skapa sagor för barn!",
    pl: "Bajki dla dzieci - Stwórz bajki dla dzieci!",
    en: "Fairy tales for children - Create fairy tales for children!",
  },
  "Sagostund.nu": {
    sv: "Sagostund.nu",
    pl: "Bajki dla dzieci",
    en: "Fairy tales for children",
  },
  "Är du säker på att du vill radera ditt konto? Alla dina sagor kommer att försvinna. Detta går inte att ångra.":
    {
      sv: "Är du säker på att du vill radera ditt konto? Alla dina sagor kommer att försvinna. Detta går inte att ångra.",
      en: "Are you sure you want to delete your account? All your fairy tales will disappear. This cannot be undone.",
      pl: "Czy jesteś pewien, że chcesz usunąć swoje konto? Wszystkie twoje bajki znikną. Tej operacji nie można cofnąć.",
    },
  Avbryt: {
    sv: "Avbryt",
    pl: "Anuluj",
  },
  "Updatera sagan": {
    sv: "Updatera sagan",
    pl: "Zaktualizuj bajkę",
  },
  "Editera text": {
    sv: "Editera text",
    pl: "Edytuj tekst",
  },
  Stoppa: {
    sv: "Stoppa",
    pl: "Zatrzymaj",
  },
  Provlyssna: {
    sv: "Provlyssna",
    pl: "Przesłuchaj",
  },
  Arne: {
    sv: "Arne",
    pl: "Adam",
  },
  Lotta: {
    sv: "Lotta",
    pl: "Maria",
  },
  OK: {
    sv: "OK",
    pl: "OK",
  },
  "Skriver din saga...": {
    sv: "Skriver din saga...",
    pl: "Pisze twoją bajkę...",
  },
  "Sagor för barn - Köp sagomynt": {
    sv: "Sagor för barn - Köp sagomynt",
    pl: "Bajki dla dzieci - Kup monety bajkowe",
  },
  "Skapa anpassad": {
    sv: "Skapa anpassad",
    pl: "Stwórz niestandardowy",
  },
  "Skapa enkelt": {
    sv: "Skapa enkelt",
    pl: "Stwórz prosty",
  },
  "Fyll på sagomynt!": {
    sv: "Fyll på sagomynt!",
    pl: "Doładuj monety bajkowe!",
  },
  "Du måste fylla på ditt konto med sagomynt för att generera ljud till din saga!":
    {
      sv: "Du måste fylla på ditt konto med sagomynt för att generera ljud till din saga!",
      pl: "Musisz doładować swoje konto monetami bajkowymi, aby wygenerować dźwięk do swojej bajki!",
    },
  "Vill du generera ljud till din saga för": {
    sv: "Vill du generera ljud till din saga för",
    pl: "Czy chcesz wygenerować dźwięk do swojej bajki za",
  },
  "Generera ljud till din saga": {
    sv: "Generera ljud till din saga",
    pl: "Wygeneruj dźwięk do swojej bajki",
  },
  "Ta en titt på våra sagor för att få inspiration till din egen saga!": {
    sv: "Ta en titt på våra sagor för att få inspiration till din egen saga!",
    pl: "Spójrz na nasze bajki, aby zainspirować się do własnej bajki!",
  },
  'I en saga ingår 3-6 sidor text med en bild till varje sida. När du klickar på "Skapa saga" så börjar din saga skapas. Inom några minuter kommer du kommer du kunna hitta den nybakade sagan under "Sagor"!':
    {
      sv: 'I en saga ingår 3-6 sidor text med en bild till varje sida. När du klickar på "Skapa saga" så börjar din saga skapas. Inom några minuter kommer du kommer du kunna hitta den nybakade sagan under "Sagor"!',
      pl: 'W bajce znajduje się 3-6 stron tekstu z obrazkiem na każdej stronie. Po kliknięciu przycisku "Stwórz bajkę" rozpocznie się tworzenie twojej bajki. W ciągu kilku minut znajdziesz świeżo upieczoną bajkę w sekcji "Bajki"!',
    },
  "Vad händer när jag skapar en saga?": {
    sv: "Vad händer när jag skapar en saga?",
    pl: "Co się dzieje, gdy tworzę bajkę?",
  },
  "Vill du beställa hem en fysisk kopia av din saga som en bok? Den kommer att tryckas och skickas till dig inom 2-5 arbetsdagar.":
    {
      sv: "Vill du beställa hem en fysisk kopia av din saga som en bok? Den kommer att tryckas och skickas till dig inom 2-5 arbetsdagar.",
      pl: "Czy chcesz zamówić fizyczną kopię swojej bajki jako książkę? Zostanie wydrukowana i wysłana do Ciebie w ciągu 2-5 dni roboczych.",
    },
  "Publicera saga": {
    sv: "Publicera saga",
    pl: "Opublikuj bajkę",
  },
  "Vill du publicera din saga så den kan läsas av andra? Din saga kommer dyka upp i listan med våra andra sagor alla kan läsa.":
    {
      sv: "Vill du publicera din saga så den kan läsas av andra? Din saga kommer dyka upp i listan med våra andra sagor alla kan läsa.",
      pl: "Czy chcesz opublikować swoją bajkę, aby mogła być czytana przez innych? Twoja bajka pojawi się na liście wraz z naszymi innymi bajkami, które każdy może przeczytać.",
    },
  "Skapa bild": {
    sv: "Skapa bild",
    pl: "Utwórz obraz",
  },
  "Du måste fylla på ditt konto med sagomynt för att skapa en ny bild!": {
    sv: "Du måste fylla på ditt konto med sagomynt för att skapa en ny bild!",
    pl: "Musisz doładować swoje konto monetami bajkowymi, aby stworzyć nowy obraz!",
  },
  "Två barn som leker i en park, blonda, en pojke och en flicka...": {
    sv: "Två barn som leker i en park, blonda, en pojke och en flicka...",
    pl: "Dwoje dzieci bawiących się w parku, blondyni, chłopiec i dziewczynka...",
  },
  "Om du vill lägga till något speciellt i din bild, beskriv det här: (om du inte vill lägga till något, lämna fältet tomt)":
    {
      sv: "Om du vill lägga till något speciellt i din bild, beskriv det här: (om du inte vill lägga till något, lämna fältet tomt)",
      pl: "Jeśli chcesz dodać coś specjalnego do swojego obrazu, opisz to tutaj: (jeśli nie chcesz nic dodawać, pozostaw pole puste)",
    },
  "till ditt stycke?": {
    sv: "till ditt stycke?",
    pl: "do twojego fragmentu?",
  },
  "Vill du skapa en ny bild för": {
    sv: "Vill du skapa en ny bild för",
    pl: "Czy chcesz stworzyć nowy obraz dla",
  },
  "Skapa ny bild till stycke": {
    sv: "Skapa ny bild till stycke",
    pl: "Utwórz nowy obraz do fragmentu",
  },
  "Beskrivning...": {
    sv: "Beskrivning...",
    pl: "Opis...",
  },
  "Beskriv hur du vill att din bild ska se ut:": {
    sv: "Beskriv hur du vill att din bild ska se ut:",
    pl: "Opisz, jak ma wyglądać twój obraz:",
  },
  "Vill du skapa en ny profilbild för": {
    sv: "Vill du skapa en ny profilbild för",
    pl: "Czy chcesz stworzyć nowy obraz profilowy dla",
  },
  "Skapa ny profilbild": {
    sv: "Skapa ny profilbild",
    pl: "Utwórz nowy obraz profilowy",
  },
  delad: {
    sv: "delad",
    pl: "udostępniona",
  },
  "Dela saga": {
    sv: "Dela saga",
    pl: "Udostępnij bajkę",
  },
  "Vill du dela din saga? Du kommer få en länk du kan kopiera och skicka till de du vill ska kunna läsa din saga.":
    {
      sv: "Vill du dela din saga? Du kommer få en länk du kan kopiera och skicka till de du vill ska kunna läsa din saga.",
      pl: "Czy chcesz udostępnić swoją bajkę? Otrzymasz link, który możesz skopiować i wysłać do tych, którzy chcą przeczytać twoją bajkę.",
    },
  "Dela din saga med familj och vänner": {
    sv: "Dela din saga med familj och vänner",
    pl: "Udostępnij swoją bajkę rodzinie i przyjaciołom",
  },
  "Skapar ljud till din saga...": {
    sv: "Skapar ljud till din saga...",
    pl: "Tworzenie dźwięku do twojej bajki...",
  },
  instagram: {
    sv: "https://www.instagram.com/sagostund_nu/",
    pl: "https://www.instagram.com/sagostund_nu/",
  },
  facebook: {
    sv: "https://www.facebook.com/profile.php?id=61552209881081",
    pl: "https://www.facebook.com/profile.php?id=61552209881081",
  },
  "Publicerade sagor": {
    sv: "Publicerade sagor",
    pl: "Opublikowane bajki",
  },
  "Skapa nya bilder": {
    sv: "Skapa nya bilder",
    pl: "Stwórz nowe obrazy",
  },
  "Editera texten": {
    sv: "Editera texten",
    pl: "Edytuj tekst",
  },
  "Denna delen håller på att skrivas, den dyker upp snart!": {
    sv: "Denna delen håller på att skrivas, den dyker upp snart!",
    pl: "Ta część jest właśnie pisana, pojawi się wkrótce!",
  },
  kapitel: {
    sv: "kapitel",
    pl: "rozdział",
  },
  "Vi skapar en ny bild till dig!": {
    sv: "Vi skapar en ny bild till dig!",
    pl: "Tworzymy dla Ciebie nowy obraz!",
  },
  "Ett fel inträffade. Vänligen försök igen.": {
    sv: "Ett fel inträffade. Vänligen försök igen.",
    pl: "Wystąpił błąd. Proszę spróbować ponownie.",
  },
  "Ett nätverksfel inträffade. Vänligen kontrollera din anslutning.": {
    sv: "Ett nätverksfel inträffade. Vänligen kontrollera din anslutning.",
    pl: "Wystąpił błąd sieciowy. Proszę sprawdzić swoje połączenie.",
  },
  "Skapar din bild!": {
    sv: "Skapar din bild!",
    pl: "Tworzymy Twój obraz!",
  },
  "Välj bilden som passar bäst för ditt stycke!": {
    sv: "Välj bilden som passar bäst för ditt stycke!",
    pl: "Wybierz obraz, który najlepiej pasuje do Twojego fragmentu!",
  },
  "Skapa bild för": {
    sv: "Skapa bild för",
    pl: "Utwórz obraz dla",
  },
  "Något gick fel när vi skulle skapa bilden för detta stycket, vi har ersatt ditt konto med":
    {
      sv: "Något gick fel när vi skulle skapa bilden för detta stycket, vi har ersatt ditt konto med",
      pl: "Coś poszło nie tak podczas tworzenia obrazu do tego fragmentu, zrekompensowaliśmy Twoje konto za",
    },

  // My stories component
  "Nyast först": {
    sv: "Nyast först",
    pl: "Najnowsze najpierw",
    en: "Newest first",
  },
  "Äldst först": {
    sv: "Äldst först",
    pl: "Najstarsze najpierw",
    en: "Oldest first",
  },
  "Sagans namn": {
    sv: "Sagans namn",
    pl: "Nazwa bajki",
    en: "Fairy tale name",
  },
  "Bilder skapas...": {
    sv: "Bilder skapas...",
    pl: "Tworzenie obrazków...",
    en: "Creating pictures...",
  },
  "Omslagsbild måste väljas": {
    sv: "Omslagsbild måste väljas",
    pl: "Obrazek okładki musi być wybrany",
    en: "Cover image must be selected",
  },
  "Misslyckades med att skapa omslagsbilden": {
    sv: "Misslyckades med att skapa omslagsbilden",
    pl: "Nie udało się utworzyć obrazka okładki",
    en: "Failed to create cover image",
  },
  "Skapa din första saga här": {
    sv: "Skapa din första saga här",
    pl: "Stwórz swoją pierwszą bajkę tutaj",
    en: "Create your first fairy tale here",
  },
  "Här kommer du kunna hitta dina sagor när du skapat dem!": {
    sv: "Här kommer du kunna hitta dina sagor när du skapat dem!",
    pl: "Tutaj będziesz mógł znaleźć swoje bajki po ich stworzeniu!",
    en: "Here you will be able to find your fairy tales when you have created them!",
  },

  // Our stories component
  "Klassiska sagor": {
    sv: "Klassiska sagor",
    pl: "Klasyczne bajki",
    en: "Classic fairy tales",
  },
  "Sagor - Våra sagor": {
    sv: "Sagor - Våra sagor",
    pl: "Bajki - Nasze bajki",
    en: "Fairy tales - Our fairy tales",
  },

  //CreatingStoryView
  "Din saga skapas": {
    sv: "Din saga skapas",
    pl: "Tworzenie twojej bajki",
    en: "Your fairy tale is being created",
  },
  "Din saga håller nu på att skrivas och illustreras! Du kan hitta sagan i Mina sagor, så fort första stycket är klart kommer du kunna börja läsa din saga.":
    {
      sv: "Din saga håller nu på att skrivas och illustreras! Du kan hitta sagan i Mina sagor, så fort första stycket är klart kommer du kunna börja läsa din saga.",
      pl: "Twoja bajka jest teraz pisana i ilustrowana! Bajkę znajdziesz w Moje bajki, jak tylko pierwszy akapit będzie gotowy, będziesz mógł zacząć czytać swoją bajkę.",
      en: "Your fairy tale is now being written and illustrated! You can find the fairy tale in My fairy tales, as soon as the first paragraph is ready you will be able to start reading your fairy tale.",
    },

  "Planerar din saga, kom tillbaka snart!": {
    sv: "Planerar din saga, kom tillbaka snart!",
    pl: "Planowanie twojej bajki, wróć wkrótce!",
    en: "Planning your fairy tale, come back soon!",
  },
  "Gå till sagan": {
    sv: "Gå till sagan",
    pl: "Przejdź do bajki",
    en: "Go to the fairy tale",
  },
  Dela: {
    sv: "Dela",
    pl: "Udostępnij",
    en: "Share",
  },
  Publicera: {
    sv: "Publicera",
    pl: "Publikuj",
    en: "Publish",
  },
  "Beställ bok": {
    sv: "Beställ bok",
    pl: "Zamów książkę",
    en: "Order book",
  },
  "Något gick fel försök gärna senare": {
    sv: "Något gick fel försök gärna senare",
    pl: "Coś poszło nie tak, spróbuj ponownie później",
    en: "Something went wrong, please try again later",
  },
  "Du måste välja en bild för varje del av din saga innan vi kan dela den!": {
    sv: "Du måste välja en bild för varje del av din saga innan vi kan dela den!",
    pl: "Musisz wybrać obrazek dla każdej części twojej bajki, zanim będziemy mogli ją udostępnić!",
    en: "You must choose an image for each part of your fairy tale before we can share it!",
  },
  "Din saga är delad!": {
    sv: "Din saga är delad!",
    pl: "Twoja bajka jest udostępniona!",
    en: "Your fairy tale is shared!",
  },

  // ArtStyleComponent
  Tecknad: {
    sv: "Tecknad",
    pl: "Rysunkowy",
    en: "Cartoon",
  },
  Realistisk: {
    sv: "Realistisk",
    pl: "Realistyczny",
    en: "Realistic",
  },
  Saga: {
    sv: "Saga",
    pl: "Bajka",
    en: "Fairy tale",
  },
  "Publik saga": {
    sv: "Publik saga",
    pl: "Opublikuj bajkę",
    en: "Public fairy tale",
  },
  "Delad saga": {
    sv: "Delad saga",
    pl: "Udostępniona bajka",
    en: "Shared fairy tale",
  },
  "Min saga": {
    sv: "Min saga",
    pl: "Moja bajka",
    en: "My fairy tale",
  },
  "Du kan inte skapa ljud till denna saga!": {
    sv: "Du kan inte skapa ljud till denna saga!",
    pl: "Nie możesz tworzyć dźwięku do tej bajki!",
    en: "You cannot create sound for this fairy tale!",
  },
  "Vi skapar en ljudfil till dig!": {
    sv: "Vi skapar en ljudfil till dig!",
    pl: "Tworzymy dla Ciebie plik dźwiękowy!",
    en: "We are creating an audio file for you!",
  },
  "Vi har fyllt på ditt konto med 100 sagomynt! Dessa kan du använda för att skapa dina första sagor!":
    {
      sv: "Vi har fyllt på ditt konto med 100 sagomynt! Dessa kan du använda för att skapa dina första sagor!",
      pl: "Doładowaliśmy twoje konto monetami bajkowymi! Możesz ich użyć do stworzenia swoich pierwszych bajek!",
      en: "We have topped up your account with 100 fairy tale coins! You can use these to create your first fairy tales!",
    },
};

export default translations;
