<template>
  <div class="fixed top-20 right-0 z-50 p-4">
    <transition-group name="list" tag="div">
      <div
        v-for="notification in notifications"
        :key="notification.id"
        class="mb-4 bg-blue-500 text-white p-4 rounded-xl m-5 w-64 relative"
      >
        <div
          @click="removeNotification(notification, true)"
          class="flex justify-between items-center font-quicksand cursor-pointer"
        >
          <div>
            {{ notification.message }}
          </div>
          <font-awesome-icon
            :icon="['fas', 'xmark']"
            class="cursor-pointer text-xl absolute top-2 right-2"
            @click="removeNotification(notification)"
          />
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
import { collection, onSnapshot, doc, updateDoc } from "firebase/firestore";
import { auth, db } from "@/firebase";

export default {
  data() {
    return {
      unsubscribe: null,
      visibleNotifications: [],
    };
  },

  computed: {
    notifications() {
      console.log("Notifications updated:", this.$store.state.notifications);
      return this.$store.state.notifications;
    },
  },

  methods: {
    async handleAddedChange(change) {
      const notification = change.doc.data();
      if (notification.visibility === true) {
        try {
          // Trigger the Vuex action or mutation to add the notification
          this.$store.dispatch("addNotification", notification);

          // Set a delay before updating the visibility
          const notificationDoc = doc(
            db,
            "users",
            auth.currentUser.uid,
            "notifications",
            change.doc.id
          );
          await updateDoc(notificationDoc, { visibility: false });
        } catch (e) {
          console.error("Failed to dispatch notification:", e);
        }
      }
    },

    removeNotification(notification, redirect = false) {
      // Trigger the fade-out animation
      this.$store.commit("HIDE_NOTIFICATION", { id: notification.id });

      // Remove the notification after the animation completes
      setTimeout(() => {
        this.$store.dispatch("removeNotification", { id: notification.id });
      }, 300);

      if (redirect && notification.link && notification.link !== "") {
        this.$router.push(notification.link);
      }
    },
  },

  created() {
    auth.onAuthStateChanged(async (user) => {
      if (user && this.unsubscribe === null) {
        try {
          const userCollection = collection(
            db,
            "users",
            user.uid,
            "notifications"
          );
          this.unsubscribe = onSnapshot(userCollection, (snapshot) => {
            snapshot.docChanges().forEach(this.handleAddedChange);
          });
        } catch (e) {
          console.error("Failed to set up Firestore snapshot:", e);
        }
      } else {
        if (this.unsubscribe !== null) {
          this.unsubscribe();
        }
      }
    });
  },

  unmounted() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  },
};
</script>

<style>
.list-enter-active,
.list-leave-active {
  transition: opacity 0.3s;
}
.list-enter, .list-leave-to /* .list-leave-active in <2.1.8 */ {
  opacity: 0;
}
.list-move {
  transition: transform 0.5s;
}
</style>
